var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Hotlist Details", gridWidth: "2/3" } },
    [
      _c("vx-card", { staticClass: "mb-5" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("travelify-display-field", {
                attrs: { label: "List Id", value: _vm.hotlistSummary.id },
              }),
              _c("travelify-display-field", {
                attrs: { label: "List Name", value: _vm.hotlistSummary.name },
              }),
              _c("travelify-display-field", {
                attrs: {
                  label: "Customer Name",
                  value: _vm.hotlistSummary.customerFullName,
                },
              }),
              _c("travelify-display-field", {
                attrs: {
                  label: "Customer Email",
                  html: _vm.hotlistSummary.customerEmail,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-1/2" },
            [
              _c("travelify-display-field", {
                attrs: { label: "Updated", value: _vm.updatedDate },
              }),
              _c("travelify-display-field", {
                attrs: { label: "Created", value: _vm.createdDate },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._l(this.hotlistItemsList, function (item, index) {
        return _c(
          "vx-card",
          {
            key: index,
            staticClass: "mb-5",
            attrs: { set: (_vm.itemdata = JSON.parse(item.itemData)) },
          },
          [
            item.productTypeId == "1"
              ? _c("flight-order-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "2"
              ? _c("accomodation-item", {
                  attrs: {
                    orderItem: item,
                    itemdata: _vm.itemdata,
                    orderdata: _vm.hotlistSummary,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "3"
              ? _c("package-holidays-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    itemdata: _vm.itemdata,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "4"
              ? _c("tickets-attractions-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    itemdata: _vm.itemdata,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "5"
              ? _c("taxis-transfer-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    itemdata: _vm.itemdata,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "6"
              ? _c("airport-extras-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    itemdata: _vm.itemdata,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "7"
              ? _c("insurance-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
            item.productTypeId == "8"
              ? _c("car-rental-item", {
                  attrs: {
                    orderData: _vm.hotlistSummary,
                    itemdata: _vm.itemdata,
                    orderItem: item,
                    isHotlist: true,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }